import React, { useEffect, useRef, useState } from 'react';

import './accordion.css';
import up from '../../assets/галочка каталога.png';

function Accordion(props) {
  const [active, setActive] = useState(props.active ?? false);
  const content = useRef(null);
  const [height, setHeight] = useState('0px');
  const accordionRef = useRef(null);

  useEffect(() => {
    setHeight(!active ? '0px' : `max-content`);
  }, [active]);

  useEffect(() => {
    setActive(false);
  }, [props.active]);

  function toggleAccordion() {
    setActive(!active);
    setHeight(active ? '0px' : `max-content`);
  }

  return (
    <div className="accordion__section" ref={accordionRef}>
      <div
        className={`accordion ${active ? 'active' : ''}`}
        onClick={toggleAccordion}
      >
        <div className="accordion__title">{props.title}</div>
        <span style={{ marginLeft: '20px' }}>
          {active ? (
            <img className="up-arr" src={up} alt="-" />
          ) : (
            <img src={up} alt="+" />
          )}
        </span>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className={
          active ? 'activeAcc accordion__content' : 'accordion__content'
        }
      >
        <div>{props.content}</div>
      </div>
    </div>
  );
}

export default Accordion;
