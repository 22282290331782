import { useEffect, useState } from "react";
import "./notice.css";

const PublicOferta = () => {
  useEffect(() => {
    localStorage.removeItem("s");
    localStorage.removeItem("cat");
    document.getElementsByClassName("hero-optional")[0] &&
      document.getElementsByClassName("hero-optional")[0].scrollIntoView();
  }, []);
  return (
      <div className="notice container">
          <h2>ПУБЛИЧНАЯ ОФЕРТА</h2>
          <p>
              Настоящий документ является официальным предложением (Публичной офертой)
              Интернет-магазина «BESTBOOKSHOP», расположенном на доменном имени
              https://bestbookshop.eu (далее «Продавец») заключить договор о продаже
              Товара дистанционным способом на изложенных ниже условиях любому
              физическому лицу (далее – «Покупатель»). Документ является Публичной
              офертой в соответствии с правом Эстонской Республики.
          </p>

          <h3>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h3>
          <p>
              1.1. Публичная оферта (далее – «Оферта») – публичное предложение
              Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом
              договор купли-продажи товара дистанционным способом (далее – «Договор»)
              на условиях, содержащихся в настоящей Оферте.
          </p>
          <p>
              1.2. Заказ Товара на сайте Интернет-магазина https://bestbookshop.eu –
              позиции, указанные Покупателем из ассортимента Товара, предложенного к
              продаже, при оформлении заявки на приобретение Товара на сайте
              Интернет-магазина.
          </p>

          <h3>2. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
          <p>
              2.1. Заказ Покупателем Товара, размещенного на сайте Интернет-магазина
              https://bestbookshop.eu означает, что Покупатель согласен со всеми
              условиями настоящей Оферты.
          </p>
          <p>
              2.2. Администрация сайта Интернет-магазина имеет право вносить изменения
              в Оферту без уведомления Покупателя.
          </p>
          <p>
              2.3. Срок действия Оферты не ограничен, если иное не указано на сайте
              Интернет-магазина.
          </p>
          <p>
              2.4. Продавец предоставляет Покупателю полную и достоверную информацию о
              Товаре, включая информацию об основных потребительских свойствах Товара
              на сайте Интернет магазина.
          </p>

          <h3>3. ЦЕНА ТОВАРА</h3>
          <p>
              3.1. Цена на каждую позицию Товара указана на сайте Интернет-магазина
              https://bestbookshop.eu .
          </p>
          <p>
              3.2. Продавец имеет право в одностороннем порядке изменить цену на любую
              позицию Товара.
          </p>
          <p>
              3.3. В случае изменения цены на заказанный Товар Продавец обязуется
              проинформировать Покупателя об изменении цены Товара информацией
              размещенной на сайте.
          </p>
          <p>
              3.4. Изменение Продавцом цены на оплаченный Покупателем Товар не
              допускается.
          </p>
          <p>
              3.5. Продавец указывает стоимость доставки Товара на сайте
              Интернет-магазина.
          </p>
          <p>
              3.6. Обязательства Покупателя по оплате Товара считаются исполненными с
              момента поступления Продавцу денежных средств.
          </p>
          <p>
              3.7. Расчеты между Продавцом и Покупателем за Товар производятся
              способами, указанными на сайте Интернет-магазина.
          </p>

          <h3>4. ОФОРМЛЕНИЕ ЗАКАЗА</h3>
          <p>
              4.1. Заказ Товара осуществляется Покупателем через сервис сайта
              Интернет-магазина https://bestbookshop.eu .
          </p>
          <p>
              4.2 При регистрации на сайте Интернет-магазина Покупатель заполняет
              следующие регистрационные поля: ─ имя пользователя; ─ адрес электронной
              почты; ─ пароль.
          </p>
          <p>
              4.3. При оформлении заказа на сайте Интернет-магазина Покупатель
              обязуется предоставить следующую регистрационную информацию:
          </p>
          <p>4.3.1. фамилия, имя Покупателя или указанного им лица (получателя);</p>
          <p>
              4.3.2. адрес, по которому следует доставить Товар (если доставка до
              адреса Покупателя);
          </p>
          <p>4.3.3. адрес электронной почты; </p>
          <p>4.3.4. контактный телефон.</p>
          <p>
              4.4. Наименование, количество, ассортимент, артикул, изображение, цена,
              вес единицы выбранного Покупателем Товара указываются в корзине
              Покупателя на сайте Интернет-магазина.
          </p>
          <p>
              4.5. Если Продавцу необходима дополнительная информация, он вправе
              запросить ее у Покупателя. В случае не предоставления необходимой
              информации Покупателем, Продавец не несет ответственности за выбранный
              Покупателем Товар.
          </p>
          <p>
              4.6. Принятие Покупателем условий настоящей Оферты осуществляется
              посредством внесения Покупателем соответствующих данных в
              регистрационную форму на сайте Интернет-магазина.{" "}
          </p>
          <p>
              4.7. Продавец не несет ответственности за содержание и достоверность
              информации, предоставленной Покупателем при оформлении Заказа.
          </p>
          <p>
              4.8. Покупатель несет ответственность за достоверность предоставленной
              информации при оформлении Заказа.
          </p>
          <p>
              4.9. Договор купли-продажи дистанционным способом между Продавцом и
              Покупателем считается заключенным с момента отправки Продавцом на
              электронный адрес Покупателю документа, подтверждающего оплату Товара.
          </p>

          <h3>5. ДОСТАВКА ТОВАРА ПОКУПАТЕЛЮ</h3>
          <p>
              5.1. Продавец доставляет Покупателю Товары в соответствии со способом,
              выбранным Покупателем при оформлении заказа.{" "}
          </p>
          <p>
              5.2. Стоимость доставки зависит от страны назначения и веса заказа.
              Узнать точную стоимость доставки можно перед оформлением заказа в
              корзине с Товаром.{" "}
          </p>
          <p>
              5.3. Адрес доставки Товара Покупатель указывает при оформлении Заказа на
              приобретение Товара.
          </p>
          <p>
              5.4. Срок доставки Товара Покупателю состоит из срока обработки заказа и
              условий доставки DHL. Доставка Товара занимает от 3 до 30 рабочих дней,
              в зависимости от страны, загруженности службы доставки и таможенной
              службы. Этот срок не включает в себя срок комплектования.
          </p>
          <p>
              5.5. Интернет-магазин «BESTBOOKSHOP» не несет ответственность за
              увеличение сроков доставки почтовыми службами, т.к. они являются
              сторонними организациями, оказывающими услугу доставки. Все претензии, в
              случае нарушения сроков доставки, нарушении целостности или потере
              посылки следует направлять в службу, услуга которой была заказана.{" "}
          </p>

          <h3>6. РЕКВИЗИТЫ ПРОДАВЦА</h3>
          <p>Premier Logistic OÜ</p>
          <p>Регистрационный номер: 11386669</p>
          <p>
              Юридический адрес: Tõru tn 15, Pirita linnaosa, Tallinn Harju maakond,
              12011, Estonia
          </p>
          <p>Уполномоченный представитель: Руслан Пагас</p>
          <p>Расчетный счет IBAN: EE021010220070832010</p>
          <p>Наименование банка: AS SEB BANK</p>
          <p>Адрес банка: Tornimae 2, Tallinn, 15010, Estonia</p>
          <p>SWIFT-код (BIC): EEUHEE2X</p>
          <p>
              Контактный телефон: <a href="tel:+491723462609">+491723462609</a>
          </p>
          <p>
              Электронный адрес:{" "}
              <a href="mailto:support@bestbookshop.eu">support@bestbookshop.eu</a>
          </p>
      </div>
  );
};

export default PublicOferta;
