import search from '../../../assets/seach icon.png';
import { useState } from 'react';
import { BASE_URL } from '../../Enter/Auth/auth';
import { send_request } from '../../Enter/Auth/setAuthToken';

export const SearchBookInput = ({ setSearch }) => {
  const [searchText, setSearchText] = useState('');

  const handleChangeInput = (e) => {
    setSearchText(e.target.value);
  };
  const handleSearch = () => {
    setSearch(searchText);
  };
  return (
    <div className="search-book-user">
      <div className="inp-block">
        <img
          src={search}
          className="pointer"
          onClick={handleSearch}
          alt="search"
        />
        <input
          type="text"
          value={searchText}
          onChange={handleChangeInput}
          placeholder="Поиск по магазину"
          className="inp-search"
        />
      </div>
    </div>
  );
};
