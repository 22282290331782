import axios from 'axios';
import { useEffect, useState } from 'react';
import '../../App.css';
import './home.css';
import CategoryMenu from '../../components/categoryMenu/CategoryMenu';
import PaginationComponent from '../../components/pagination/pagination';
import { BASE_URL } from '../Enter/Auth/auth';
import PaginationPanel from '../../components/paginationPanel/paginationPanel';
import Cookie from '../../components/cookie/Cookie';
import { Link } from 'react-router-dom';

const Home = () => {
  let [data, setData] = useState();
  let [sort, setSort] = useState();
  let [isLimit, setIsLimit] = useState(true);
  let [limit, setLimit] = useState(
    window.localStorage.getItem('pageN')
      ? window.localStorage.getItem('pageN') * 15 + 5
      : 20
  );
  let [isCookie, setIsCookie] = useState(false);
  let [recomends, setRecomends] = useState();
  let [pageN, setPageN] = useState(
    window.localStorage.getItem('pageN')
      ? window.localStorage.getItem('pageN')
      : 1
  );

  let [total, setTotal] = useState(0);
  let [offset, setOffset] = useState(0);

  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    axios
      .get(BASE_URL + 'books/', {
        params: {
          limit: limit,
          id: 0,
          sort: localStorage.getItem('sort'),
          categories: localStorage.getItem('cat'),
          offset,
          search: searchParams.get('search'),
        },
      })
      .then((data) => {
        if (data.data.bookCards == null) {
          // window.location.assign('/not-found')
        } else if (
          (data.data.bookCards.length < limit && offset < limit) ||
          limit <= 0
        ) {
          setIsLimit(false);
        } else {
          setIsLimit(true);
        }
        setData(data.data);
        setTotal(data.data.total);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign('/error');
        }
      });
  }, [sort, limit, offset]);
  useEffect(() => {
    if (!localStorage.getItem('cookie')) {
      setIsCookie(true);
    }
    setLimit(pageN * 15 + 5);
    axios
      .get(BASE_URL + 'books/', { params: { limit: 8, id: 0, sort: sort } })
      .then((data) => {
        setRecomends(data.data);
      })
      .catch((err) => {
        if (err.response.status === 500) {
          window.location.assign('/error');
        }
      });
  }, []);

  const changeCookie = () => {
    setIsCookie(false);
  };
  const addSort = (value) => {
    setSort(value);
    localStorage.setItem('sort', value);
  };

  const handleSetPage = (page) => {
    setOffset(page.selected * limit);
    window.scrollTo(0, 300);
  };

  return (
    <>
      {data && data.bookCards ? (
        <div className="home container">
          <div className="pag-comp">
            {data && (
              <>
                <PaginationComponent data={data} addSort={addSort} grid={4} />
                {isLimit && (
                  <PaginationPanel
                    handleSetPage={handleSetPage}
                    pagesCount={Math.ceil(total / limit)}
                  />
                )}
              </>
            )}
          </div>
          <div className="category-menu">
            <CategoryMenu />
          </div>
        </div>
      ) : (
        data !== undefined && (
          <div className="empty container">
            <p className="text-empty">Результаты поиска</p>
            <div className="text-empty-res">
              Извините, по вашему запросу ничего не найдено
            </div>
            <p className="text-empty">
              <b>Возможно, вам понравится:</b>
              <Link
                className="link-back"
                onClick={() => {
                  localStorage.removeItem('s');
                  localStorage.removeItem('cat');
                  window.location.reload();
                }}
                to={'/'}
              >
                Перейти на главную
              </Link>
            </p>
            <PaginationComponent
              data={recomends}
              addSort={addSort}
              grid={5}
              limit={limit}
            />
          </div>
        )
      )}
      {isCookie == true && (
        <div className="block-cookie">
          <Cookie changeCookie={changeCookie} />
        </div>
      )}
    </>
  );
};

export default Home;
