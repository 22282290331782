import ReactPaginate from 'react-paginate';
import './pagination-panel.css';

const PaginationPanel = ({ handleSetPage, pagesCount }) => {
  return (
    // <div className="pagination-panel">
    //     <button onClick={nextPage} className='btn'>Посмотреть еще</button>
    // </div>
    <ReactPaginate
      breakLabel="..."
      nextLabel=">"
      previousLabel="<"
      onPageChange={handleSetPage}
      pageRangeDisplayed={3}
      marginPagesDisplayed={2}
      activeClassName="active"
      containerClassName="pagination-list"
      pageCount={pagesCount}
      // renderOnZeroPageCount={null}
    />
  );
};

export default PaginationPanel;
